import axios from "axios";

const AxiosInstance = axios.create({
  baseURL: `${process.env.VUE_APP_API}${process.env.VUE_APP_PATH}`,
  headers: {
    "Content-Type": "application/json; charset=utf-8",
    pragma: "no-cache",
    "cache-control": "no-cache",
  },
});

// Error Handler
const errorHandler = (statusCode) => {
  if (statusCode === 9002) {
    window.location = "/login";
  }
};

AxiosInstance.interceptors.request.use(
  (config) => {
    const reqConfig = config;

    return reqConfig;
  },
  (err) => {
    if (err && err.response) {
      return Promise.reject(err);
    }
    return Promise.reject(err.response);
  },
);

AxiosInstance.interceptors.response.use(
  (response) => {
    const { code } = response.data;
    if (code === 9002) {
      errorHandler(code);
    } else {
      return response;
    }
  },
  (err) => {
    const { response } = err;
    if (response) {
      const { status, data } = response;
      errorHandler(status, data);
      return Promise.reject(err.response);
    } else {
      if (!window.navigator.onLine) {
        alert("沒開網路");
      } else {
        return Promise.reject(err);
      }
    }
  },
);

const APIService = {
  query(url, json) {
    return AxiosInstance.get(url, {
      params: json,
    });
  },
  get(url, slug) {
    return AxiosInstance.get(`${url}/${slug}`);
  },
  getSlug(url, slug, json) {
    return AxiosInstance.get(`${url}/${slug}`, { params: json });
  },
  create(url, data) {
    return AxiosInstance.post(url, data);
  },
  createParams(url, params) {
    return AxiosInstance.post(`${url}?${params}`);
  },
  createParamsSlug(url, params, data) {
    return AxiosInstance.post(`${url}?${params}`, data);
  },
  update(url, slug, data) {
    return AxiosInstance.patch(`${url}/${slug}`, data);
  },
  delete(url, slug) {
    return AxiosInstance.delete(`/${url}/${slug}`);
  },
  form(url, data) {
    return AxiosInstance.post(url, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  formUpdate(url, slug, data) {
    return AxiosInstance.patch(`/${url}/${slug}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
};

export default APIService;
